<template>
  <tr>
    <td>
      {{ day.toLocaleDateString("en-CH") }}
    </td>
    <td>
      {{ weekdays[day.getDay()] }}
    </td>
    <td>
      <div style="line-height: 16px">
          <strong style="font-size: 1rem;">{{article?.content_topic_data.name}}</strong> <br />
          {{ article?.content_topic_data.topic_name }} {{ article?.content_topic_data.sub_topic != null ? " > " + article?.content_topic_data.sub_topic_name : "" }}
      </div>
    </td>
    <td>
      {{ article?.writer_data.first_name }} {{ article?.writer_data.last_name }}
    </td>
    <td>
      {{ article?.learning_verse }}
    </td>
    <td>
      {{ article?.learning_verse_ref }}
    </td>
    <td v-if="instanceData.hasEditorSelection">
      {{ article?.assigned_editor_data?.first_name }} {{ article?.assigned_editor_data?.last_name }} 
    </td>
    <td>
      <v-icon
          v-if="article"
          class="mx-4"
          :color="`${article.used ? 'green' : 'grey'}`"
          :key="article.used"
      >
        {{
          article.used
              ? "mdi-check-circle-outline"
              : "mdi-close-circle-outline"
        }}
      </v-icon>
    </td>

    <td class="text-right">
      <template v-if="article?.reused_article">
        <span :key="article.id" v-if="!article.used">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  :key="article.id"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span>
              Dieser Plan enthält ein Archivartikel. <br/>
              Dieser wird genutzt um einen neuen Artikel zu
              erstellen
            </span>
          </v-tooltip>

          <v-btn
              class="mx-2"
              small
              elevation="0"
              @click="$emit('createFromArchive', article.id)"
          >
            erstellen
          </v-btn>

          <v-btn
              class="mr-2"
              text
              icon
              @click="$emit('previewArticle', article.reused_article)"
          >
            <v-icon>mdi-text-box-search-outline</v-icon>
          </v-btn>
        </span>
        <v-tooltip v-else bottom :key="article.id">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on">
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>
            Der Artikel zu diesem Plan wurde mithilfe eines
            Archivartikels erstellt
          </span>
        </v-tooltip>
      </template>
      <v-btn
          icon
          text
          elevation="0"
          @click="$emit('openDialog', day)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import projectMixin from "@/mixins/projectMixin"

export default {
  name: "ArticlePlanningTableRow",
  props: {
    day: Date,
    article: Object,
  },
  mixins: [projectMixin],
  data() {
    return {
      weekdays: [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ],
    }
  }
}
</script>

<style scoped>

</style>
