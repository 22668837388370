import Repository from "./Repository";

const resource = "/mailings";

export default {
    getAllActiveAuthors() {
        return Repository.get(`${resource}/recipients_all_active_authors/`);
    },
    sendToAllActiveAuthors(subject, text) {
        return Repository.post(`${resource}/all_active_authors/`,
            { 'subject': subject, 'text': text }
        );
    },

    getAllPlannedAuthors(id) {
        return Repository.get(`${resource}/recipients_all_planned_authors/?project_id=${id}`);
    },
    sendToAllPlannedAuthors(id, subject, text) {
        return Repository.post(
            `${resource}/all_planned_authors/`,
            { 'project_id': id, 'subject': subject, 'text': text }
        );
    },

    getAllPlannedWithoutEdits(id) {
        return Repository.get(`${resource}/recipients_all_planned_authors_without_edits/?project_id=${id}`);
    },
    sendToAllPlannedWithoutEdits(id, subject, text) {
        return Repository.post(`${resource}/all_planned_authors_without_edits/?project_id=${id}`,
            { 'project_id': id, 'subject': subject, 'text': text }
        );
    },

    getAllPlannedWithUnfinished(id) {
        return Repository.get(`${resource}/recipients_all_planned_authors_with_unfinished_articles/?project_id=${id}`);
    },
    sendToAllPlannedWithUnfinished(id, subject, text) {
        return Repository.post(`${resource}/all_planned_authors_with_unfinished_articles/?project_id=${id}`,
            { 'project_id': id, 'subject': subject, 'text': text }
        );
    },

}
