<template>
  <div class="preview_container print-card">
    <div class="text-center">
      <v-progress-circular
        v-if="loading"
        class="my-15"
        :size="50"
        color="primary"
        indeterminate
      />
    </div>

    <div class="preview_content" v-if="!loading">
      <div v-if="show_ids" style="position: absolute; top: -20px;">
        <v-chip x-small> #{{ article.id }} </v-chip>
      </div>
      <div class="preview_date_box">
        <div v-if="article.assigned_date" class="preview_weekday">
          {{ article.assigned_date | luxon("EEEE") }}
          <div class="preview_rbr mt-1">
            Bibellese:
          </div>
          <div class="preview_rbr">
            {{ article.recommended_bible_reference }}
          </div>
        </div>
        <div v-else class="preview_weekday">WOCHENTAG</div>
        <div class="preview_date">
          <div v-if="article.assigned_date" class="preview_day">
            <div
              v-if="$luxon(article.assigned_date, 'd').length == 1"
              style="position: relative; left: 2mm; font-size: 29pt;"
            >
              {{ article.assigned_date | luxon("d") }}
            </div>
            <div
              v-else
              style="position: relative; right: 1mm; font-size: 29pt;"
            >
              {{ article.assigned_date | luxon("d") }}
            </div>
            <div class="preview_year">
              {{ article.assigned_date | luxon("yyyy") }}
            </div>

            <img
              src="../../../assets/article_preview/page.png"
              class="preview_calender_page"
            />
          </div>
          <div v-else class="preview_day">00</div>
          <div v-if="article.assigned_date" class="preview_month">
            {{ article.assigned_date | luxon("MMMM") }}
            <div class="preview_event mt-1" v-if="article.event_day_data">
              {{ article.event_day_data.name }}
            </div>
          </div>
          <div v-else class="preview_month">MONAT</div>
        </div>
      </div>
      <hr id="preview_date_line" />
      <v-row
        class="preview_votd_img mt-10"
        v-if="this.hyphenated_votd.length < 80"
      >
        <v-col cols="6">
          <div class="preview_votd_box">
            <div class="preview_votd">
              <div>
                {{ this.hyphenated_votd }}
                <span class="preview_votd_ref">
                  <template v-if="article.votd_is_excerpt">
                    Aus
                  </template>
                  <template v-if="article.votd_reference_overwrite">
                    {{ votd_ref }}
                  </template>
                  <template v-else>
                    {{ votd_ref.split(",")[0] }},
                    {{ votd_ref.split(",")[1].includes("-") ? "Verse" : "Vers" }}
                    {{ votd_ref.split(",")[1] }}
                  </template>
                </span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>

      <div class="preview_votd_box mt-13" v-else>
        <div class="preview_votd">
          <div>
            {{ this.hyphenated_votd }}
            <span class="preview_votd_ref">{{ votd_ref }}</span>
          </div>
        </div>
      </div>
      <div
        class="preview_text preview_text_style"
        :style="`${this.hyphenated_votd.length >= 80 ? 'top: 37px;' : ''}`"
      >
        <h2 class="preview_title">{{ article.title }}</h2>
        <img
          src="../../../assets/article_preview/category_example_bible.jpg"
          class="preview_topic_img"
        />
        <div
          style="text-align:right; position:absolute; left: -28px; background-color:gold; padding: 0 2px;"
          v-if="show_lines"
          class="preview_text_style"
        >
          1<br />2<br />3<br />4<br />5<br />6<br />7<br />8<br />9<br />10<br />11<br />
          <span style="color: darkgreen"> 12<br />13<br />14 </span>
          <br />
          <span style="color: darkorange">
            15
          </span>
          <span style="color: darkred"> <br />16</span><br />
        </div>
        <div class="preview_text_img_floater"></div>
        <div v-html="this.hyphenated_text" id="output_text"></div>
        <div
          class="preview_author_name"
          v-if="article.writer_data !== undefined"
        >
          {{ article.writer_data.short }}
        </div>
      </div>
      <div class="preview_bottom_grid">
        <hr />
        <div>
          <span style="font-family: 'Lato-Bold', sans-serif;">Lernvers:</span>
          {{ this.hyphenated_learning_verse }}
          <div class="preview_lv_ref">
            {{ article.learning_verse_ref }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hyphenate } from "hyphen/de"
import bibleRefMixin from "@/mixins/bibleRefMixin"

export default {
  name: "ArticlePrintLayout",
  props: {
    article: Object,
    show_lines: Boolean,
    show_ids: Boolean,
    loading: Boolean,
  },
  mixins: [bibleRefMixin],
  data() {
    return {
      hyphenated_text: "",
      hyphenated_votd: "",
      hyphenated_learning_verse: "",
      votd_ref: "",
    }
  },
  created() {
    if (!this.loading) this.prepareContent()
  },
  watch: {
    loading(loading) {
      if (!loading) {
        this.prepareContent()
      }
    },
  },
  methods: {
    prepareContent() {
      hyphenate(this.article.text, { minWordLength: 5 }).then((result) => {
        this.hyphenated_text = result
      })
      hyphenate(this.article.votd, { minWordLength: 5 }).then((result) => {
        this.hyphenated_votd = result
      })
      hyphenate(this.article.learning_verse, { minWordLength: 5 }).then(
        (result) => {
          this.hyphenated_learning_verse = result
        }
      )
      this.votd_ref = this.parseBibleRef(
        this.article.votd_reference,
        this.article.votd_reference_overwrite
      )
    },
  },
}
</script>

<style scoped>
.preview_container {
  width: 355px;
  height: 510px;
  background-color: white;
  box-shadow: black 1px 1px 4px;
  padding: 25px 36px 35px 29px;
  color: #333;
}

.preview_content {
  position: relative;
  height: 100%;
}

.preview_date_box {
  /* font-family: "Montserrat", sans-serif; */
  /* display: grid;
  grid-template-columns: auto auto; */
  position: relative;
  height: 40px;
}

.preview_date {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  position: absolute;
  right: 0;
  grid-gap: 7px;
}

.preview_weekday {
  font-family: "Wurper-Regular", sans-serif;
  position: absolute;
  top: 30px;
  left: 0;
  font-size: 15pt;
  line-height: 18pt;
}

.preview_day {
  font-family: "Vanilla", sans-serif;
  font-weight: 600;
  font-size: 31pt;
  letter-spacing: -0.6pt;
  position: absolute;
  top: 20px;
  left: -3.5em;
  height: 80px;
  z-index: 2;
}

.preview_calender_page {
  position: relative;
  top: -80px;
  left: -25%;
  height: 27.688mm;
  width: 22.039mm;
  z-index: -1;
}

.preview_month {
  font-family: "Wurper-Regular", sans-serif;
  text-align: right;
  font-size: 15pt;
  line-height: 18pt;
  position: absolute;
  top: 30px;
  right: 0;
}

.preview_year {
  font-family: "Wurper-Regular", sans-serif;
  font-size: 12pt;
  line-height: 14.4pt;
  letter-spacing: normal;
  position: relative;
  top: 10px;
  left: 7.5px;
}

.preview_event {
  font-family: "Lato-Regular", sans-serif;
  font-size: 8.25pt;
  line-height: 9.9pt;
  letter-spacing: -0.6pt;
  text-align: end;
}

.preview_rbr {
  font-family: "Lato-Regular", sans-serif;
  font-size: 8.25pt;
  line-height: 9.9pt;
  letter-spacing: -0.6pt;
}

/* .preview_votd_box { */
/* font-family: "Montserrat", sans-serif; */
/* border-style: dashed;
  border-color: #333;
  border-width: 1px 0;
  margin: 5px 0;
  padding: 5px 0;
  display: grid;
  grid-template-columns: 30px 1fr 30px; */
/* } */

/* .preview_votd_img{
  height: 60px;
  margin-left: -12px;
} */

.preview_votd_img {
  font-family: "Lato-Bold", sans-serif;
  height: 110px;
}

.preview_votd {
  font-family: "Lato-Bold", sans-serif;
  font-size: 11pt;
  white-space: pre-line;
  line-height: 13pt;
  letter-spacing: -0.6pt;
}

.preview_votd_ref {
  font-family: "Lato-Regular", sans-serif;
  font-weight: 400;
  font-size: 9pt;
  display: block;
  line-height: 11pt;
  letter-spacing: -0.6pt;
}

.preview_title {
  font-family: "Lato-Bold", sans-serif;
  font-size: 8.25pt;
  letter-spacing: -0.6pt;
  text-align: left;
  margin-top: 14px;
  font-weight: 800;
  margin-left: 9mm;
  margin-bottom: 2px;
  line-height: 11pt;
}

.preview_topic_img {
  position: absolute;
  left: -15px;
  top: -10px;
  height: 9.4mm;
  width: 12.48mm;
}

.preview_article_img {
  height: 100px;
  width: 140px;
}

.preview_text_img_floater {
  float: left;
  width: 9mm;
  height: 2mm;
}

.preview_text {
  position: relative;
  white-space: pre-line;
}

.preview_text_style {
  font-family: "Lato-Regular", serif;
  /* font-size: 12.7px; Value that looks the same on all Browsers. Even Chrome on Windows!! :D */
  /* font-size: 0.877em; */
  font-size: 8.25pt;
  line-height: 11pt;
  letter-spacing: -0.4pt;
}

.v-input--selection-controls {
  margin-top: 0;
}

#output_text {
  text-align: justify;
  /* letter-spacing: normal; */
}

.preview_author_name {
  font-family: "Lato-Regular", serif;
  text-align: right;
  position: relative;
  top: -11pt;
}

.preview_bottom_grid {
  font-family: "Lato-Regular", sans-serif;
  font-size: 8.25pt;
  line-height: 11pt;
  letter-spacing: -0.4pt;
  /* display: grid; */
  /* grid-template-columns: max-content 1fr; */
  /* grid-gap: 6px; */
  position: absolute;
  bottom: -12px;
  text-align: justify;
}

.preview_bottom_grid div {
  height: 26px;
}

.preview_lv_ref {
  text-align: right;
  position: relative;
  top: -11pt;
}

#preview_date_line {
  position: relative;
  top: 15px;
}

@media print {
  .print-card {
    margin-top: 75px;
    margin-bottom: 100px;
  }
}
</style>

<style>
#output_text p {
  margin-bottom: 0;
}

#output_text p:not(:first-child) {
  text-indent: 0px;
}

#output_text p:after {
  content: "";
  display: inline-block;
  width: 0;
}
</style>
