<template>
  <v-app id="inspire">
    <MenuSetDefault />

    <v-main>
      <div class="text-left">
        <v-sheet color="accent" class="py-5 px-5">
          <h3>Beitragsplanung</h3>
        </v-sheet>
      </div>

      <v-card class="elevation-0 px-5">
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab v-for="i in 12" :key="i" dens>
              {{ months[i - 1].name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="i in 12" :key="i">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Wochentag</th>
                      <th>Arbeitstitel</th>
                      <th>Autor</th>
                      <th>Lernvers</th>
                      <th>Lernversposition</th>
                      <th v-if="instanceData.hasEditorSelection">Lektor</th>
                      <th>Erstellt</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <ArticlePlanningTableRow
                        v-for="day in getAllDaysInMonth($store.state.currentProjectMeta.year,i - 1)"
                        :key="day.toString()"
                        :day="day"
                        :article="getArticleOfDay(day)"
                        @openDialog="openDialog"
                        @createFromArchive="createFromArchive"
                        @previewArticle="previewArticle"
                    />
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-dialog v-model="dialog" width="700" @click:outside="cancelDialog">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Beitragsplanung für
              {{ weekdays[new Date(selectedPlan.assigned_date).getDay()] }}
              den
              {{ new Date(selectedPlan.assigned_date).toLocaleDateString() }}
            </v-card-title>

            <v-card-text>
              <div class="text-right">
                <v-btn
                  v-if="selectedPlan.reused_article"
                  class="mt-2"
                  @click="resetSelection(true)"
                  color="red"
                >
                  Archivartikel entfernen
                </v-btn>
                <v-btn v-else class="mt-2" @click="openArchive">
                  Aus dem Archiv füllen
                </v-btn>
              </div>
              <v-autocomplete
                label="Autor *"
                item-text="full_name"
                item-value="id"
                :items="authors"
                v-model="selectedPlan.writer"
                :disabled="selectedPlan.reused_article ? true : false"
                :rules="[(v) => !!v || 'Autor ist ein Pflichtfeld']"
              />
              <v-autocomplete
                v-if="instanceData.hasEditorSelection"
                label="Lektor *"
                item-text="full_name"
                item-value="id"
                :items="editors"
                v-model="selectedPlan.assigned_editor"
                :disabled="selectedPlan.reused_article ? true : false"
                :rules="[(v) => !!v || 'Lektor ist ein Pflichtfeld']"
              />
              <v-select
                label="Arbeitstitel"
                v-model="contentTopic"
                :items="contentTopics"
                :disabled="selectedPlan.reused_article ? true : false"
                item-text="name"
                filled
                class="mb-4"
                hide-details
                return-object
              >
                <template v-slot:append-outer>
                  <v-btn icon @click="newContentTopic = true">
                    <v-icon>mdi-plus-thick</v-icon>
                  </v-btn>
                </template>
              </v-select>
              <TopicSelection v-model="topic" disabled />
              <!-- :disabled="disableTopicSelection ? true : false" -->
              <DialogNewContentTopic
                :dialog="newContentTopic"
                @close="useNewContentTopic"
              />
              <v-textarea
                label="Lernvers"
                v-model="selectedPlan.learning_verse"
                :disabled="selectedPlan.reused_article ? true : false"
              />
              <v-text-field
                label="Lernvers Bibelstelle"
                v-model="selectedPlan.learning_verse_ref"
                :disabled="selectedPlan.reused_article ? true : false"
              />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="primary" text @click="cancelDialog">
                abbrechen
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" outlined @click="saveAndClose">
                speichern
              </v-btn>
              <v-btn color="primary" @click="saveAndNext">
                speichern und nächsten bearbeiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-dialog
        v-model="archiveDialog"
        width="300"
        @click:outside="cancelArchiveDialog"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Beitrag aus dem Archiv nutzen
          </v-card-title>
          <v-card-text class="text-center">
            Geben Sie die ID des Beitrags ein, den sie nutzen möchten:
            <v-text-field v-model.number="archiveID" />
            <v-btn @click="getArchiveArticle">Beitrag suchen</v-btn>
            <div v-if="Object.keys(archiveArticle).length > 0">
              {{ archiveArticle.title }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="previewItem()" v-bind="attrs" v-on="on">
                    <v-icon>mdi-text-box-search-outline</v-icon>
                  </v-btn>
                </template>
                <span>Vorschau</span>
              </v-tooltip>
              <v-btn @click="useArchiveArticle">
                Diesen Beitrag nutzen
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="cancelArchiveDialog">
              abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <DialogPreviewFull
        :dialog="previewDialog"
        :article_id="archiveID"
        @close="closePreview"
      />
    </v-main>
  </v-app>
</template>

<script>
import MenuSetDefault from "@/components/layout/MenuSetDefault"
import DialogPreviewFull from "@/components/articles/DialogPreviewFull"
import TopicSelection from "@/components/articles/TopicSelection"
import DialogNewContentTopic from "@/components/articles/DialogNewContentTopic"
import ArticlePlanningTableRow from "@/components/articles/articlePlannings/ArticlePlanningTableRow"

import projectMixin from "@/mixins/projectMixin"

import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const ArticlesRepository = RepositoryFactory.get("articles")
const TopicsRepository = RepositoryFactory.get("topics")
const UsersRepository = RepositoryFactory.get("users")
const PlannedArticlesRepository = RepositoryFactory.get("plannedArticles")

export default {
  name: "Beitragsplanung",
  mixins: [projectMixin],
  components: {
    MenuSetDefault,
    DialogPreviewFull,
    TopicSelection,
    DialogNewContentTopic,
    ArticlePlanningTableRow,
  },
  data() {
    return {
      dialog: false,
      archiveDialog: false,
      previewDialog: false,
      year: null,
      valid: false,
      weekdays: [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ],
      months: [
        { name: "Januar", length: 31 },
        { name: "Februar", length: 28 },
        { name: "März", length: 31 },
        { name: "April", length: 30 },
        { name: "Mai", length: 31 },
        { name: "Juni", length: 30 },
        { name: "Juli", length: 31 },
        { name: "August", length: 31 },
        { name: "September", length: 30 },
        { name: "Oktober", length: 31 },
        { name: "November", length: 30 },
        { name: "Dezember", length: 31 },
      ],
      articles: [],
      selectedPlan: {},
      archiveID: 0,
      archiveArticle: {},
      contentTopics: [],
      contentTopic: null,
      topics: {},
      topic: {},
      subTopics: {},
      authors: [],
      editors: [],
      tab: 0,
      disableTopicSelection: false,
      newContentTopic: false,
    }
  },
  async created() {
    const self = this

    await this.fetchTopics()
    await this.fetchArticlePlannings()

    await UsersRepository.get().then(function(response) {
      self.authors = response.data
        //use group name instead of id
        .filter((item) => item.groups_data.find((x) => x.name === "Author"))
        .map((item) => {
          return {
            id: item.id,
            full_name: item.full_name,
            // ...item,
          }
        })
    })
    await UsersRepository.get().then(function(response) {
      self.editors = response.data
        //use group name instead of id
        .filter((item) => item.groups_data.find((x) => x.name === "Editor"))
        .map((item) => {
          return {
            id: item.id,
            full_name: item.full_name,
            // ...item,
          }
        })
    })
  },
  methods: {
    async fetchTopics() {
      const { data: topicsData } = await TopicsRepository.get()
      this.topics = topicsData
      const { data: subTopicsData } = await TopicsRepository.getSubTopics()
      this.subTopics = subTopicsData
      const {
        data: contentTopicsData,
      } = await TopicsRepository.getContentTopics()
      this.contentTopics = contentTopicsData
      this.contentTopics = this.contentTopics
        .filter((e) => e.active == true)
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        }).sort(function (a, b) {
          return a.topic_name.localeCompare(b.topic_name);
        })
    },
    async fetchArticlePlannings() {
      const {
        data: plannedArticlesData,
      } = await PlannedArticlesRepository.get()
      this.articles = plannedArticlesData
    },
    getArticleOfDay(day){
      return this.articles.find((article) => new Date(article.assigned_date)
            .toLocaleDateString() === day.toLocaleDateString()
      )
    },
    openDialog(selectedDate) {
      selectedDate.setDate(selectedDate.getDate() + 1)
      let formattedDate = this.$luxon(
        selectedDate.toISOString().slice(0, 10),
        "yyyy-MM-dd"
      )
      let articlePlan = this.articles.find(
        (i) => i.assigned_date == formattedDate
      )
      if (articlePlan) {
        this.selectedPlan = articlePlan
        this.contentTopic = articlePlan.content_topic_data
      } else {
        this.selectedPlan = {}
      }
      if (Object.keys(this.selectedPlan).length == 0) {
        this.selectedPlan = {
          assigned_date: this.$luxon(
            selectedDate.toISOString().slice(0, 10),
            "yyyy-MM-dd"
          ),
          writer: this.authors[0].id,
          content_topic: 1,
        }
        if (this.instanceData.hasEditorSelection) {
          this.selectedPlan.assigned_editor = this.editors[0].id
        }
      }
      this.dialog = true
      this.$refs.form.resetValidation()
    },
    cancelDialog() {
      this.dialog = false
      this.resetSelection(false)
      this.topic = {}
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
    async savePlanning() {
      let newContentTopic = null

      if (this.selectedPlan.id) {
        await PlannedArticlesRepository.update(
          this.selectedPlan.id,
          this.selectedPlan.assigned_date,
          this.$store.getters.currentProject,
          this.selectedPlan.writer,
          newContentTopic ? newContentTopic.id : this.contentTopic.id,
          this.selectedPlan.reused_article,
          this.selectedPlan.learning_verse,
          this.selectedPlan.learning_verse_ref,
          this.selectedPlan.assigned_editor
        )
      } else {
        await PlannedArticlesRepository.add(
          this.selectedPlan.assigned_date,
          this.$store.getters.currentProject,
          this.selectedPlan.writer,
          newContentTopic ? newContentTopic.id : this.contentTopic.id,
          this.selectedPlan.reused_article,
          this.selectedPlan.learning_verse,
          this.selectedPlan.learning_verse_ref,
          this.selectedPlan.assigned_editor
        )
      }

      await this.fetchTopics()
      await this.fetchArticlePlannings()
    },
    async saveAndClose() {
      await this.savePlanning()

      this.cancelDialog()
    },
    async saveAndNext() {
      await this.savePlanning()
      var nextDate = new Date(this.selectedPlan.assigned_date)
      nextDate.setDate(nextDate.getDate() + 1)
      if (this.$refs.form.validate()) {
        this.selectedPlan.id = null
        for (let article in this.articles) {
          article = this.articles[article]
          if (
            article.assigned_date ==
            this.$luxon(nextDate.toISOString().slice(0, 10), "yyyy-MM-dd")
          ) {
            this.selectedPlan = article
            this.contentTopic = article.content_topic_data

            break
          }
        }
        this.selectedPlan.assigned_date = this.$luxon(
          nextDate.toISOString().slice(0, 10),
          "yyyy-MM-dd"
        )
        this.$refs.form.resetValidation()
      }
    },
    getAllDaysInMonth(year, month) {
      const date = new Date(year, month, 1)

      const dates = []

      while (date.getMonth() === month) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      return dates
    },
    openArchive() {
      this.dialog = false
      this.archiveDialog = true
    },
    cancelArchiveDialog() {
      this.archiveID = 0
      this.archiveArticle = {}
      this.archiveDialog = false
      this.dialog = true
    },
    async getArchiveArticle() {
      const { data } = await ArticlesRepository.getArticle(this.archiveID)
      this.archiveArticle = data
    },
    useArchiveArticle() {
      this.archiveDialog = false
      this.selectedPlan.writer = this.archiveArticle.writer
      this.selectedPlan.reused_article = this.archiveArticle.id
      this.selectedPlan.learning_verse = this.archiveArticle.learning_verse
      this.selectedPlan.learning_verse_ref = this.archiveArticle.learning_verse_ref
      this.contentTopic = this.archiveArticle.content_topic_data
      this.dialog = true
    },
    async createFromArchive(id) {
      await PlannedArticlesRepository.createFromArchive(id)

      const {
        data: plannedArticlesData,
      } = await PlannedArticlesRepository.get()
      this.articles = plannedArticlesData
    },
    resetSelection(keepID) {
      this.selectedPlan = {
        id: keepID ? this.selectedPlan.id : undefined,
        reused_article: keepID ? null : this.selectedPlan.reused_article,
        assigned_date: this.selectedPlan.assigned_date,
        writer: this.authors[0].id,
        content_topic: 1,
      }
    },
    previewArticle(articleID) {
      this.archiveID = articleID
      this.previewDialog = true
    },
    previewItem() {
      this.previewDialog = true
    },
    closePreview() {
      this.previewDialog = false
    },
    setSelectedTopic(contentTopic) {
      let topicIdentifier = contentTopic.topic

      if (contentTopic.sub_topic) {
        topicIdentifier = topicIdentifier + "-" + contentTopic.sub_topic
      }

      if (
        this.topics.filter((topic) => topic.identifier == topicIdentifier)
          .length > 0
      ) {
        this.topic = this.topics.filter(
          (topic) => topic.identifier == topicIdentifier
        )[0]
      } else {
        this.topic = this.subTopics.filter(
          (subTopic) => subTopic.identifier == topicIdentifier
        )[0]
      }
    },
    async useNewContentTopic(nct = null) {
      if (nct) {
        this.contentTopic = nct

        const {
          data: contentTopicsData,
        } = await TopicsRepository.getContentTopics()
        this.contentTopics = contentTopicsData
      }

      this.newContentTopic = false
    },
  },
  watch: {
    contentTopic(newValue) {
      if (newValue != null && typeof newValue === "object") {
        this.setSelectedTopic(newValue)
        this.disableTopicSelection = true
      } else {
        this.disableTopicSelection = false
      }
    },
  },
}
</script>
