<template>
  <v-dialog v-model="dialog" persistent max-width="1600px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear
            background-color="white"
            color="primary"
            indeterminate
        />
      </template>

      <v-card-title>
        <span class="headline">
          Beitrag 
          "{{ article.title }}"
          bearbeiten
          <template v-if="article.assigned_date">
            ({{ new Date(article.assigned_date).toLocaleDateString("en-CH")  }})
          </template>
        </span>
      </v-card-title>

      <v-card-subtitle>
        mit * gekennzeichnete Felder sind Pflichtfelder.
      </v-card-subtitle>

      <v-tabs
        v-if="instanceData.hasSongs"
        v-model="tab"
        align-with-title
      >
        <v-tab>
          Eintrag
        </v-tab>
        <v-tab>
          Liederbuch
          <v-icon v-if="article.used_song_text">mdi-check</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>          
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="7">

                    <span class="form-header">Inhalt</span>

                    <v-divider class="mb-3"/>

                    <v-row>
                      <v-col cols="12">
                        <template v-if="contentTopic">
                          <span class="text-h6">
                            Thematik "{{ contentTopic.name }}"
                          </span>
                        </template>
                        <v-text-field
                            v-model="article.title"
                            :rules="[v => !!v || 'Titel ist ein Pflichtfeld']"
                            label="Titel*"
                            required
                            filled
                        />
                      </v-col>

                      <v-col cols="12">
                        <span class="form-header">Beitragstext*</span>

                        <tiptap-vuetify
                            @init="onInitTiptap"
                            :card-props="{ flat: true, color: '#d8dad7', 'min-height': 300 }"
                            :toolbar-attributes="{ color: 'primary', dark: true }"
                            v-model="articleText"
                            :extensions="extensions"
                        />

                        <small v-if="!!this.tiptapEditor"
                              :class="this.htmlToText(this.tiptapEditor.getHTML()).length > 1700 ? 'text--warn' : ''">{{
                            this.htmlToText(this.tiptapEditor.getHTML()).length
                          }}/{{instanceData.articleTextLength}}</small>

                        <v-menu open-on-hover
                                top
                                offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                x-small
                                dark
                                v-bind="attrs"
                                v-on="on"
                                elevation="0"
                                class="float-right mt-1 ml-1"
                            >
                              Sonderzeichen
                            </v-btn>
                          </template>

                          <v-row class="pa-4 accent-bg">
                            <v-col v-for="(item, index) in specialChars" :key="index" cols="6" class="charMenuCol pa-1">
                              <v-btn small elevation="0" block @click="insertSymbol(item)">{{ item }}</v-btn>
                            </v-col>
                            <v-col cols="6" class="charMenuCol pa-1">
                              <v-btn small elevation="0" block @click="insertSymbol('\u00A0')" class="text-lowercase">&&#xFEFF;nbsp;</v-btn>
                            </v-col>
                          </v-row>
                        </v-menu>

                        <v-btn x-small elevation="0" class="float-right mt-1 ml-1" @click="replaceChars()">Sonderzeichen
                          umwandeln
                        </v-btn>
                      </v-col>

                      <v-col cols="12"
                      v-if="instanceData.articleQuestionField"
                      >
                        <span class="form-header">Frage</span>
                        <tiptap-vuetify
                            @init="onInitQuestionTiptap"
                            :card-props="{ flat: true, color: '#d8dad7' }"
                            :toolbar-attributes="{ color: 'primary', dark: true }"
                            v-model="article.question"
                            :extensions="questionHintExtensions"
                        />
                      </v-col>

                      <v-col cols="12"
                      v-if="instanceData.articleHintField"
                      >
                        <span class="form-header">Tipp</span>
                        <tiptap-vuetify
                            @init="onInitHintTiptap"
                            :card-props="{ flat: true, color: '#d8dad7' }"
                            :toolbar-attributes="{ color: 'primary', dark: true }"
                            v-model="article.hint"
                            :extensions="questionHintExtensions"
                        />
                      </v-col>

                      <v-col cols="12">
                        <small v-if="article.recommended_bible_reference">Übereinstimmung prüfen:
                          <a
                              target="_blank"
                              :href="bibleServerURLRecommendation"
                          >
                            {{ decodeURI(bibleServerURLRecommendation) }}
                          </a>
                        </small>

                        <v-text-field
                            v-if="!instanceData.hasReadingPlan"
                            :disabled="!this.$store.getters.isEditor"
                            v-model="article.recommended_bible_reference"
                            label="Bibellesestelle"
                            filled
                        ></v-text-field>
                        <v-text-field
                            v-if="instanceData.hasReadingPlan"
                            :disabled="!this.$store.getters.isEditor"
                            v-model="article.recommended_bible_reference"
                            label="Bibellesestelle"
                            filled
                        ></v-text-field>
                        <v-text-field
                            v-if="instanceData.hasReadingPlan"
                            :disabled="!this.$store.getters.isEditor"
                            v-model="article.learning_verse"
                            label="Lernvers"
                            filled
                        ></v-text-field>
                        <v-text-field
                            v-if="instanceData.hasReadingPlan"
                            :disabled="!this.$store.getters.isEditor"
                            v-model="article.learning_verse_ref"
                            label="Lernversposition"
                            filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5">

                    <span class="form-header">Vers des Tages</span>

                    <v-divider class="mb-3"/>

                    <template v-if="!instanceData.hasDoubleVotd">
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_book"
                              :rules="[v => !!v || 'Buch ist ein Pflichtfeld']"
                              :items="votd_books"
                              item-value="number"
                              item-text="name"
                              label="Buch*"
                              required
                              filled
                          />
                        </v-col>
      
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="article.votd_bible_translation"
                              :rules="[v => !!v || 'Übersetzung ist ein Pflichtfeld']"
                              :items="translations"
                              item-value="id"
                              item-text="name_full"
                              label="Übersetzung*"
                              required
                              filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="4">
                          <v-autocomplete
                              v-model="votd_chapter"
                              :rules="[v => !!v || 'Kapitel ist ein Pflichtfeld']"
                              :items="votd_chapters"
                              item-text="chapter"
                              label="Kapitel*"
                              required
                              filled
                          />
                        </v-col>
      
                        <v-col cols="4">
                          <v-autocomplete
                              v-model="votd_verse"
                              :rules="[v => !!v || 'Vers ist ein Pflichtfeld']"
                              :items="votd_verses"
                              label="Vers*"
                              required
                              filled
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                              v-model="votd_verse_end"
                              :rules="[v => (!v || v > this.votd_verse) || 'Vers Ende muss größer sein als der Beginn']"
                              :items="votd_verses"
                              label="Vers Ende"
                              clearable
                              filled
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text-right py-0">
                          <v-btn
                              text
                              x-small
                              color="secondary"
                              dark
                              @click="showVotdRefOverwrite = !showVotdRefOverwrite"
                          >
                            Benutzerdefinierte Tagesvers Bibelstelle
                          </v-btn>
                          <v-text-field v-if="showVotdRefOverwrite"
                                        v-model="article.votd_reference_overwrite"
                                        label="Benutzerdefinierte Tagesvers Bibelstelle"
                                        filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="12">
                          <small v-if="bibleServerURL">Übereinstimmung prüfen:
                            <a
                                target="_blank"
                                :href="bibleServerURL"
                            >
                              {{ decodeURI(bibleServerURL) }}
                            </a>
                          </small>
      
                          <v-textarea
                              v-model="article.votd"
                              :rules="[v => !!v || 'Tagesvers ist ein Pflichtfeld']"
                              label="Tagesvers*"
                              :counter="instanceData.verseLength"
                              rows="2"
                              auto-grow
                              filled
                              required
                          />
      
                          <v-checkbox
                            v-if="instanceData.hasVotdExcerpt"
                            v-model="article.votd_is_excerpt"
                            class="mt-0"
                            label="Tagesvers ist Auszug"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_book"
                              :rules="[v => !!v || 'Buch ist ein Pflichtfeld']"
                              :items="votd_books"
                              item-value="number"
                              item-text="name"
                              label="Buch*"
                              required
                              filled
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_chapter"
                              :rules="[v => !!v || 'Kapitel ist ein Pflichtfeld']"
                              :items="votd_chapters"
                              item-text="chapter"
                              label="Kapitel*"
                              required
                              filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_verse"
                              :rules="[v => !!v || 'Vers ist ein Pflichtfeld']"
                              :items="votd_verses"
                              label="Vers*"
                              required
                              filled
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                              v-model="votd_verse_end"
                              :rules="[v => (!v || v > this.votd_verse) || 'Vers Ende muss größer sein als der Beginn']"
                              :items="votd_verses"
                              label="Vers Ende"
                              clearable
                              filled
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="text-right py-0">
                          <v-btn
                              text
                              x-small
                              color="secondary"
                              dark
                              @click="showVotdRefOverwrite = !showVotdRefOverwrite"
                          >
                            Benutzerdefinierte Tagesvers Bibelstelle
                          </v-btn>
                          <v-text-field v-if="showVotdRefOverwrite"
                                        v-model="article.votd_reference_overwrite"
                                        label="Benutzerdefinierte Tagesvers Bibelstelle"
                                        filled
                          />
                        </v-col>
                      </v-row>
      
                      <v-row>
                        <v-col cols="12">
                          <small v-if="validReference">Übereinstimmung prüfen:
                            <a
                            target="_blank"
                            :href="`https://www.bibleserver.com/ELB/${validReference}`"
                            >
                            {{ decodeURI(`https://www.bibleserver.com/ELB/${validReference}`) }}
                            </a>
                          </small>
      
                          <v-textarea
                              v-model="article.votd"
                              :rules="[v => !!v || 'Tagesvers ist ein Pflichtfeld']"
                              label="Tagesvers ELB*"
                              :counter="instanceData.verseLength"
                              rows="2"
                              auto-grow
                              filled
                              required
                          />
      
                          <v-checkbox
                            v-if="instanceData.hasVotdExcerpt"
                            v-model="article.votd_is_excerpt"
                            class="mt-0"
                            label="Tagesvers ist Auszug"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <small v-if="validReference">Übereinstimmung prüfen:
                            <a
                            target="_blank"
                            :href="`https://www.bibleserver.com/NeÜ/${validReference}`"
                            >
                            {{ decodeURI(`https://www.bibleserver.com/NeÜ/${validReference}`) }}
                            </a>
                          </small>
      
                          <v-textarea
                              v-model="article.votd_alt"
                              :rules="[v => !!v || 'Tagesvers ist ein Pflichtfeld']"
                              label="Tagesvers NeÜ*"
                              :counter="instanceData.verseLength"
                              rows="2"
                              auto-grow
                              filled
                              required
                          />
      
                          <v-checkbox
                            v-if="instanceData.hasVotdExcerpt"
                            v-model="article.votd_alt_is_excerpt"
                            class="mt-0 mb-4"
                            label="Tagesvers ist Auszug"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </template>

                    <span class="form-header">Thema</span>

                    <v-divider class="mb-3"/>

                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-if="instanceData.hasContentTopics"
                          :disabled="!this.$store.getters.isEditor"
                          label="Arbeitstitel"
                          v-model="contentTopic"
                          :items="contentTopics"
                          item-text="name"
                          filled
                          class="mb-4"
                          hide-details
                          return-object
                        >
                          <template v-slot:append-outer>
                            <v-btn icon @click="newContentTopic = true">
                              <v-icon>mdi-plus-thick</v-icon>
                            </v-btn>
                          </template>
                        </v-select>
                        <TopicSelection
                          v-model="topic"
                          :disabled="!this.$store.getters.isEditor"
                        />
                        <DialogNewContentTopic
                          v-if="instanceData.hasContentTopics"
                          :dialog="newContentTopic"
                          @close="useNewContentTopic"
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-autocomplete
                            @change="updateEventDays"
                            :disabled="!this.$store.getters.isEditor"
                            v-model="article.event_day_topic"
                            :items="eventTopics"
                            item-value="id"
                            item-text="name"
                            label="Anlass"
                            clearable
                            filled
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-autocomplete
                            v-model="article.event_day"
                            :disabled="!this.$store.getters.isEditor"
                            :items="events"
                            item-value="id"
                            item-text="name"
                            label="Ereignis"
                            clearable
                            filled
                        />
                      </v-col>

                      <v-col cols="12" v-if="!this.$store.getters.isEditor">
                        <v-textarea
                            v-model="comment_author"
                            label="Kommentar"
                            rows="2"
                            auto-grow
                            filled
                        />
                      </v-col>

                      <v-col cols="12" v-if="this.$store.getters.isEditor || this.$store.getters.isAdmin">
                        <v-autocomplete
                            v-model="article.writer"
                            :items="authors"
                            item-value="id"
                            item-text="full_name"
                            label="Autor"
                            clearable
                            filled
                        />
                      </v-col>
                    </v-row>

                    <template v-if="!instanceData.hasSongs">
                      <v-divider/>
  
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn color="primary" text @click="reset">Zurücksetzen</v-btn>
                        <v-btn color="primary" text @click="close">Schließen</v-btn>
                        <v-btn color="primary" text @click="submit('intermediate')">Zwischenspeichern</v-btn>
                        <v-btn color="primary" elevation="0" @click="submit('close')">Speichern</v-btn>
                      </v-card-actions>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-tab-item>
        <v-tab-item
          v-if="instanceData.hasSongs"
        >
          <SongSelection 
            :song="article.used_song_text_data"
            v-model="article.song_text" 
            @songSelected="updateSong"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions v-if=instanceData.hasSongs>
        <v-spacer/>
        <v-btn color="primary" text @click="reset">Zurücksetzen</v-btn>
        <v-btn color="primary" text @click="close">Schließen</v-btn>
        <v-btn color="primary" text @click="submit('intermediate')">Zwischenspeichern</v-btn>
        <v-btn color="primary" elevation="0" @click="submit('close')">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import {TiptapVuetify, Bold, Italic, History /*BulletList, OrderedList, ListItem, Paragraph */} from 'tiptap-vuetify'
import textModifyMixin from '@/mixins/textModifyMixin'
import bibleRefMixin from '@/mixins/bibleRefMixin'
import projectMixin from '@/mixins/projectMixin'

import DialogNewContentTopic from "../articles/DialogNewContentTopic"
import TopicSelection from "../articles/TopicSelection"
import SongSelection from "../articles/SongSelection"

const BibleTranslationRepository = RepositoryFactory.get('bible_translations');
const TopicsRepository = RepositoryFactory.get('topics');
const EventsRepository = RepositoryFactory.get('events');
const ArticlesRepository = RepositoryFactory.get('articles');
const BibleRepository = RepositoryFactory.get('bible');
const UsersRepository = RepositoryFactory.get('users');
const ReadingPlanRepository = RepositoryFactory.get("readingPlans")

export default {
  name: "DialogArticleEdit",
  components: {
    TiptapVuetify,
    TopicSelection,
    DialogNewContentTopic,
    SongSelection
  },
  mixins: [textModifyMixin, bibleRefMixin, projectMixin],
  props: {
    article_id: Number,
    articleProp: Object,
    dialog: Boolean,
  },
  data() {
    return {
      article: [],
      newText: '',
      loading: false,
      translations: [],
      topics: [],
      topic: null,
      contentTopic: null,
      events: [],
      event: null,
      authors: [],
      eventTopics: [],
      eventTopic: null,
      votd_book: null,
      votd_books: [],
      votd_chapter: null,
      votd_chapters: [],
      votd_verse: null,
      votd_verse_end: null,
      votd_verses: [],
      votd_ref: '',
      votd_is_excerpt: '',
      valid: true,
      loading_obj: [],
      comment_author: '',
      tiptapEditor: null,
      tiptapIsValid: false,
      tiptapText: '',
      tiptapIsEmpty: false,
      extensions: [
        Bold,
        Italic,
        History
        // ListItem,
        // BulletList,
        // OrderedList,
        // Paragraph,
      ],
      questionHintExtensions: [
        Bold,
        Italic
      ],
      specialChars: {
        doubleQuoteRight: '\u00bb',
        doubleQuoteLeft: '\u00ab',
        quoteRight: '\u203a',
        quoteLeft: '\u2039',
        dash: '\u2013',
        apostrophe: '\u02bc',
        dat: '\u2022',
        frac12: '\u00bd',
        frac14: '\u00bc',
        frac34: '\u00be',

      },
      showVotdRefOverwrite: false,
      readingPlan: {},
      contentTopics: [],
      newContentTopic: false,
      tab: 0,
      // songs: []
    }
  },
  methods: {
    async fetch() {
      if (this.articleProp === undefined) {
        const {data} = await ArticlesRepository.getArticle(this.article_id);
        this.article = data;
      } else {
        this.article = this.articleProp;
      }

      let votd_split = this.article.votd_reference.split(';');
      this.votd_book = parseInt(votd_split[0]);
      this.votd_chapter = parseInt(votd_split[1]);
      this.votd_verse = parseInt(votd_split[2]);
      this.newText = this.article.text;
      this.comment_author = this.article.comment_author
      if (typeof votd_split[3] !== 'undefined' && votd_split[3] != "") {
        this.votd_verse_end = parseInt(votd_split[3]);
      }
      this.showVotdRefOverwrite = (this.article.votd_reference_overwrite !== "");
      this.loading_obj.article = false;
      this.votd_is_excerpt = this.article.votd_is_excerpt

      this.check_loading();
    },
    async fetchUsers() {
      this.loading = true;
      const {data} = await UsersRepository.get();
      this.authors = data;
      this.loading = false;
    },
    async fetchTranslations() {
      const {data} = await BibleTranslationRepository.get();
      this.translations = data;
      this.loading_obj.translations = false;
      this.check_loading();
    },
    async fetchTopics() {
      const { data: topicsData } = await TopicsRepository.get()
      this.topics = topicsData
      if(this.instanceData.hasSubTopics) {
        const { data: subTopicsData } = await TopicsRepository.getSubTopics()
        this.subTopics = subTopicsData
      }
      if(this.instanceData.hasContentTopics) {
        if (this.article.content_topic) {
          const {data: contentTopicData} = await TopicsRepository.getContentTopic(this.article.content_topic);
          this.contentTopic = contentTopicData
        }
        const {data: contentTopicsData} = await TopicsRepository.getContentTopics()
        this.contentTopics = contentTopicsData
        this.contentTopics = this.contentTopics
          .filter((e) => e.id == this.article.content_topic || e.active == true)
          .sort(function (a, b) {
            return a.name.localeCompare(b.name);
          }).sort(function (a, b) {
            return a.topic_name.localeCompare(b.topic_name);
          })
      }
      this.loading_obj.topics = false;
      this.check_loading();
    },
    async fetchEvents() {
      const {data} = await EventsRepository.get();
      this.events = data;
      this.loading_obj.events = false;
      this.check_loading();
    },
    async fetchEventTopics() {
      const {data} = await EventsRepository.getEventTopics();
      this.eventTopics = data;
      this.loading_obj.event_topics = false;
      this.check_loading();
    },
    // async fetchSongs() {
    //   const {data} = await SongsRepository.get()
    //   this.songs = data
    // },
    setSelectedTopic() {
      let articleTopic = null
      if (this.contentTopic) {
        articleTopic = this.contentTopic.topic
        
        if (this.contentTopic.sub_topic) {
          articleTopic = articleTopic + "-" + this.contentTopic.sub_topic
        }
      } else {
        articleTopic = this.article.topic_data.identifier
      }

      if (
        this.topics.filter((topic) => topic.identifier == articleTopic).length > 0
      ) {
        this.topic = this.topics.filter(
          (topic) => topic.identifier == articleTopic
        )[0]
      } else {
        this.topic = this.subTopics.filter(
          (subTopic) => subTopic.identifier == articleTopic
        )[0]
      }
    },
    async getReadingPlan() {
      this.loading = true;
      const { data: readingPlanData } = await ReadingPlanRepository.getByDate(this.date)
      this.form_recommended_bible_ref = readingPlanData.recommended_bible_reference,
      this.form_learning_verse = readingPlanData.learning_verse,
      this.form_learning_verse_ref = readingPlanData.learning_verse_ref,
      this.loading = false;
    },
    async submit(type) {
      this.tiptapText = this.htmlToText(this.tiptapEditor.getHTML())

      this.tiptapIsEmpty = this.tiptapText === '';
      this.tiptapIsValid = !this.tiptapIsEmpty;
      if (this.topic) {
        this.article.topic = this.topic.identifier
      }

      if (this.$refs.form.validate() && this.tiptapIsValid) {
        if (typeof this.article.topic === 'undefined') {
          this.article.topic = null
        }
        if (typeof this.article.event_day_topic === 'undefined') {
          this.article.event_day_topic = null
        }
        if (typeof this.article.event_day === 'undefined') {
          this.article.event_day = null
        }
        if (this.$store.state.user.id !== this.article.writer && this.article.status === 10) {
          this.article.status = 20;
        }
        if (!(this.$store.getters.isEditor || this.$store.getters.isAdmin)) {
          this.article.writer = false;
          this.article.status = false;
          this.replaceChars();
        } else {
          this.fixSpacesAll();
        }

        let newContentTopic = null
        // if (
        //   typeof this.contentTopic === "string" ||
        //   this.contentTopic instanceof String
        // ) {
        //   let topicIdentifiers = this.topic.identifier.split("-")
        //   newContentTopic = await TopicsRepository.addContentTopic(
        //     this.contentTopic,
        //     topicIdentifiers[0],
        //     topicIdentifiers[1]
        //   )
        // }

        const {data} = await ArticlesRepository.update(
            this.article.id,
            this.article.title,
            this.newText,
            this.article.votd,
            this.votd_book + ';' + this.votd_chapter + ';' + this.votd_verse + ';' + (this.votd_verse_end === undefined || this.votd_verse_end == null ? "" : this.votd_verse_end),
            this.article.votd_bible_translation,
            this.article.topic,
            newContentTopic ? newContentTopic.data.id : this.contentTopic?.id,
            this.article.question,
            this.article.hint,
            this.article.event_day_topic,
            this.article.event_day,
            this.article.recommended_bible_reference,
            this.article.learning_verse,
            this.article.learning_verse_ref,
            this.article.votd_reference_overwrite,
            this.article.votd_is_excerpt,
            this.article.status,
            this.article.writer,
            this.comment_author,
            this.article.used_song_text,
            this.article.song_text,
            this.article.votd_alt,
            this.votd_book + ';' + this.votd_chapter + ';' + this.votd_verse + ';' + (this.votd_verse_end === undefined || this.votd_verse_end == null ? "" : this.votd_verse_end),
            this.article.votd_alt_is_excerpt,
            this.article.votd_bible_translation,
        );
        if (type === 'close') {
          this.$emit('edited', data);
          this.close()
        } else {
          this.$emit('intermediateUpdate', data);
        }
      }
    },
    reset() {
      this.loading = true;
      this.fetch();
      // this.$refs.form.reset()
      this.loading = false;
    },
    async fetchBooks() {
      const {data} = await BibleRepository.getBooks();
      this.votd_books = data;
      this.loading_obj.books = false;
      this.check_loading();
    },
    async fetchChapters(book_id) {
      this.loading = true;
      const {data} = await BibleRepository.getChaptersByBook(book_id);
      this.votd_chapters = data;
      if (this.votd_chapter == null || this.votd_chapter > this.votd_chapters.length) {
        this.votd_chapter = null;
        this.votd_verses = [];
        this.votd_verse = null;
      } else {
        this.fill_verse_options(this.votd_chapter)
      }
      this.loading_obj.chapters = false;
      this.check_loading();
    },
    fill_verse_options(chapter_id) {
      this.votd_verses = [];
      let versesAmount = this.votd_chapters.find(obj => {
        return obj.chapter === chapter_id;
      }).verse_count;
      for (let i = 1; i <= versesAmount; i++) {
        this.votd_verses.push(i);
      }
      if (this.votd_verse != null && this.votd_verse > versesAmount) {
        this.votd_verse = null;
      }
      if (this.votd_verse_end != null && this.votd_verse_end > versesAmount) {
        this.votd_verse_end = null;
      }
    },
    async fetchEventDaysByTopic(topic_id) {
      this.loading = true;
      this.loading_obj.events = true;
      const {data} = await EventsRepository.getDays(topic_id);
      this.events = data;
      this.loading_obj.events = false;
      this.check_loading();
    },
    updateEventDays() {
      if (typeof this.article.event_day_topic !== 'undefined') {
        this.fetchEventDaysByTopic(this.article.event_day_topic);
      } else {
        this.fetchEvents();
      }
    },
    check_loading() {
      if (this.loading_obj.article === false && this.loading_obj.translations === false && this.loading_obj.topics === false && this.loading_obj.events === false && this.loading_obj.event_topics === false && this.loading_obj.books === false && this.loading_obj.chapters === false) {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close', this.editedItem);
    },
    onInitTiptap(e) {
      this.tiptapEditor = e.editor
    },
    onInitQuestionTiptap(e) {
      this.questionTiptapEditor = e.editor
    },
    onInitHintTiptap(e) {
      this.hintTiptapEditor = e.editor
    },
    htmlToText(html) {
      return html === undefined ? '' : html.replace(/<[^>]*>?/gm, '');
    },
    replaceChars() {
      this.newText = this.replaceSpecialChars(this.tiptapEditor.getHTML());
      this.article.text = this.replaceSpecialChars(this.tiptapEditor.getHTML());
      this.article.question = this.replaceSpecialChars(this.article.question);
      this.article.hint = this.replaceSpecialChars(this.article.hint);
      this.article.votd = this.replaceSpecialChars(this.article.votd);
      this.article.title = this.replaceSpecialChars(this.article.title);
      this.article.recommended_bible_reference = this.replaceSpecialChars(this.article.recommended_bible_reference);
    },
    fixSpacesAll() {
      this.newText = this.fixSpaces(this.tiptapEditor.getHTML());
      this.article.text = this.fixSpaces(this.tiptapEditor.getHTML());
      this.article.question = this.fixSpaces(this.article.question);
      this.article.hint = this.fixSpaces(this.article.hint);
      this.article.votd = this.fixSpaces(this.article.votd);
      this.article.title = this.fixSpaces(this.article.title);
      this.article.recommended_bible_reference = this.fixSpaces(this.article.recommended_bible_reference);
    },
    insertSymbol(specialChar) {
      let selection = window.getSelection().getRangeAt(0);
      if (selection.toString().length === 0 && selection.startContainer.nodeName === '#text') {
        let element = document.createElement("p");
        element.appendChild(document.createTextNode(specialChar));
        selection.insertNode(element);
      }
    },
    async useNewContentTopic(nct = null) {
      if (nct) {
        this.contentTopic = nct
  
        const {data: contentTopicsData} = await TopicsRepository.getContentTopics()
        this.contentTopics = contentTopicsData
      }

      this.newContentTopic = false
    },
    updateSong(newSong) {
      this.article.used_song_text = newSong.id
      this.article.used_song_text_data = newSong
    }
  },
  computed: {
    articleText: {
      get: function () {
        return this.article === undefined ? '' : this.article.text;
      },
      set: function (newValue) {
        this.newText = newValue;
        this.article.text = newValue;
      }
    },
    validReference: {
      get: function () {
        if (this.votd_book && this.votd_chapter && this.votd_verse) {
          let verseEnd = "";
          if (this.votd_verse_end !== undefined && this.votd_verse_end !== null && this.votd_verse_end > this.votd_verse) {
            verseEnd = this.votd_verse_end;
          }
          return this.parseBibleRef(this.votd_book + ';' + this.votd_chapter + ';' + this.votd_verse + ';' + verseEnd);
        } else {
          return false;
        }

      }
    },
    bibleServerURL: {
      get: function () {
        if (this.validReference && this.article.votd_bible_translation) {
          if(this.translations.find(x => x.id === this.article.votd_bible_translation)) {
            let translationName = this.translations.find(x => x.id === this.article.votd_bible_translation).name;
            if (['andere'].includes(translationName))
              return false
            if (translationName == "ELB CSV") {
              let invalidReference = this.validReference
              invalidReference = invalidReference.replace(".", "")
              invalidReference = invalidReference.split(" ")
              let newValidReference = invalidReference.join("-")
              return encodeURI(
                `https://www.csv-bibel.de/bibel/${newValidReference}`
              )
            }
            return encodeURI(`https://www.bibleserver.com/${translationName}/${this.validReference}`)
          }
          return false;
        } else {
          return false;
        }
      }
    },
    bibleServerURLRecommendation: {
      get: function () {
        if (this.article.recommended_bible_reference) {
          return encodeURI(`https://www.bibleserver.com/ELB/${this.article.recommended_bible_reference}`)
        } else {
          return false;
        }
      }
    }
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        this.loading = true;
        this.loading_obj = {
          article: true,
          translations: true,
          topics: true,
          events: true,
          event_topics: true,
          books: true,
          chapters: false,
        };
        await this.fetch();
        this.fetchTranslations();
        this.fetchEvents();
        this.fetchEventTopics();
        this.fetchBooks();
        await this.fetchTopics();
        // this.fetchSongs()

        this.setSelectedTopic()

        if (this.$store.getters.isEditor || this.$store.getters.isAdmin) {
          this.fetchUsers();
        }
        if(this.date) {
          await this.getReadingPlan();
        }
      }
    },
    votd_book(newValue) {
      // loading obj for chapters is only set to true, when votd_book changes
      // else it would never complete loading if this dialog is re-opened with the same votd_book variable
      this.loading_obj.chapters = true;
      this.fetchChapters(newValue);
    },
    votd_chapter(newValue) {
      if (this.votd_chapters.length > 0 && this.votd_chapter != null) {
        this.fill_verse_options(newValue);
      } else if (this.votd_chapter == null) {
        this.votd_verse = null;
        this.votd_verse_end = null;
      }
    },
    contentTopic(newValue) {
      if (newValue != null && typeof newValue === "object") {
        this.setSelectedTopic(newValue)
        this.disableTopic = true
      } else {
        this.disableTopic = false
      }
    }
    // loading_obj:{
    //     handler(newValue, oldValue){
    //         // if(newValue.article == false && newValue.translation == false && newValue.topics == false && newValue.events == false && newValue.event_topics == false && newValue.books == false && newValue.chapters == false){
    //         //     this.loading = false;
    //         // }else{
    //         //     this.loading = true;
    //         // }
    //         console.log('OUTPUT:');
    //         console.log(newValue);
    //         console.log(oldValue);
    //     },
    //     deep:true
    // }
  }
}
</script>

<style>
.form-header {
  color: #333333;
}

.charMenuCol {
  width: 10px !important;
}

.accent-bg {
  background-color: rgb(216, 218, 215);
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}
</style>
