const strings = {
    lim: {
        TITLE: "Leben ist mehr",
        ABBREVIATION: "LIM",
        EMAIL: "info@lim.clv.de",
        APILINK: "https://api.lim.clv.de",
        REPOLINK: "https://git.einz.de/clv/clv-lim-redaktion",
        EDITORIALEMAIL: "redaktion@lim.clv.de"
    },
    hs: {
        TITLE: "Die Helle Straße",
        ABBREVIATION: "DHS",
        EMAIL: "info@hellestrasse.cv-dillenburg.de",
        APILINK: "https://api.hellestrasse.cv-dillenburg.de",
        REPOLINK: "https://git.einz.de/clv/clv-lim-redaktion",
        EDITORIALEMAIL: "redaktion@hellestrasse.cv-dillenburg.de"
    },
    gl: {
        TITLE: "Glauben.Leben",
        ABBREVIATION: "GL",
        EMAIL: "info@glauben.leben.cv-dillenburg.de",
        APILINK: "https://api.glauben.leben.cv-dillenburg.de",
        REPOLINK: "https://git.einz.de/clv/clv-lim-redaktion",
        EDITORIALEMAIL: "redaktion@glauben.leben.cv-dillenburg.de"
    },
}

const projectData = {
    lim: {
        articleTextLength: 1700,
        verseLength: 180,
        articleQuestionField: true,
        articleHintField: true,
        hasArchive: true,
        hasFreeArticleSubmission: true,
        previewDialogWidth: '510px',
    },
    hs: {
        yearOrder: [3, 0, 1, 2],
        articleTextLength: 930,
        verseLength: 110,
        hasReadingPlan: true,
        hasArticlePlan: true,
        hasHistoricalArticles: true,
        hasContentTopics: true,
        hasSubTopics: true,
        hasVotdExcerpt: true,
        previewDialogWidth: '400px',
    },
    gl: {
        yearOrder: [3, 0, 1, 2],
        articleTextLength: 1300,
        verseLength: 110,
        hasReadingPlan: true,
        hasArticlePlan: true,
        hasContentTopics: true,
        hasSubTopics: true,
        hasVotdExcerpt: true,
        previewDialogWidth: '780px',
        hasSongs: true,
        hasEditorSelection: true,
        hasDoubleVotd: true,
        hasSunMoon: true
    }
}
// yearOrder: [0, 1, 2, 3],
// articleTextLength: 1000,
// articleQuestionField: false,
// articleHintField: false,
// hasArchive: false,
// hasFreeArticleSubmission: false,
// previewDialogWidth: '510px',
// verseLength: 110,
// hasReadingPlan: false,
// hasArticlePlan: false,
// hasHistoricalArticles: false,
// hasContentTopics: false,
// hasSubTopics: false,
// hasVotdExcerpt: false,
// hasSongs: false,
// hasEditorSelection: false,
// hasDoubleVotd: false,
// hasSunMoon: false,



export default {
    data() {
        let instanceData = projectData[process.env.VUE_APP_PROJECT_ID]

        return {
            instanceData
        }
    },
    methods: {
        __getString(name) {
            return strings[process.env.VUE_APP_PROJECT_ID][name] || ''
        }
    },
}